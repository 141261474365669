<template>
  <el-dialog title="网点设置"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="70%">
    <el-form ref="form"
             :model="form"
             :rules="rules"
             label-position="top">
      <el-form-item label="活动网点"
                    prop="net">
        <Area @change="areaChange"
              v-model="address"
              :disabled="disabled" />
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="form.is_open"></el-switch>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"
                 @click="submitForm('form')">立即{{form.research_area_id ?'修改':'创建'}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { setResearchNet } from '@/api/market'
import Area from '@/components/Area'
export default {
  props: ['disabled'],
  data () {
    return {
      address: {},
      dialogVisible: false,
      form: {
        activity_id: '',
        province: '',
        city: '',
        district: '',
        net: '',
        is_open: false
      },
      rules: {
        net: [
          { required: true, message: '请选择网点', trigger: 'change' }
        ]
      }
    }
  },
  components: {
    Area
  },
  methods: {
    areaChange (res) {
      const { form } = this
      form.province = res.province
      form.city = res.city
      form.district = res.district
      form.net = res.net
    },
    showModel (query, area, row) {
      this.dialogVisible = true
      if (row) {
        this.address = {
          province: row.province,
          city: row.city,
          district: row.district,
          net: row.net
        }
        this.$nextTick(() => {
          this.form = Object.assign({}, row)
          this.form.research_area_id = row.id
          this.form.activity_id = query.id
          this.$refs.form.resetFields()
        })
      } else {
        this.address = {
          province: area.province,
          city: area.city,
          district: area.district,
          net: area.net
        }
        this.form = {
          activity_id: query.id,
          province: '',
          city: '',
          district: '',
          net: '',
          is_open: false
        }
        this.$nextTick(() => {
          this.$refs.form.resetFields()
        })
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await setResearchNet(this.form)
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.car_area_id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$emit('success')
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
