<template>
  <el-dialog title="提货"
             :close-on-click-modal='false'
             :visible.sync="dialogVisible"
             width="50%">
    <el-form :model="form"
             :rules="rules"
             ref="form"
             label-position="top"
             class="demo-ruleForm">
      <el-form-item label="手机号"
                    prop="tel">
        <el-input v-model="form.tel"></el-input>
      </el-form-item>
      <el-form-item label="身份证号码">
        <el-input v-model="form.id_number"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer"
          class="dialog-footer">
      <el-button type="primary"
                 @click="submitForm('form')">立即提货</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { convertCustomer } from '@/api/market'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {
        tel: '',
        id_number: ''
      },
      rules: {
        tel: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        id_number: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' }
        ]
      }
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
    showModal (row) {
      this.dialogVisible = true
      this.form = {
        tel: row.tel,
        id_number: row.id_number,
        activity_id: row.activity_id,
        research_area_id: row.research_area_id,
        customer_id: row.id
      }
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('确认提货', {
            type: 'warning'
          }).then(async () => {
            const res = await convertCustomer(this.form)
            if (res.meta.code === 0) {
              this.$notify({
                title: '成功',
                message: '提货成功',
                type: 'success'
              })
              this.$emit('success')
            } else {
              this.$message.error(res.meta.msg)
            }
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
</style>
