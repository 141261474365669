<template>
  <div class="container">
    <el-dialog title="客户列表"
               :close-on-click-modal='false'
               :visible.sync="dialogVisible"
               width="80%">
      <table-page data-name="customers"
                  :tabList="tabList"
                  ref="table"
                  init
                  :params="paramsObj"
                  :search="search"
                  :request="fetchResearchCustomers">
        <el-table-column prop="id"
                         show-overflow-tooltip
                         label="编号"></el-table-column>
        <el-table-column prop="name"
                         show-overflow-tooltip
                         label="姓名"></el-table-column>
        <el-table-column prop="tel"
                         show-overflow-tooltip
                         label="手机号码"></el-table-column>
        <el-table-column prop="id_number"
                         show-overflow-tooltip
                         label="身份证"></el-table-column>
        <el-table-column prop="social_security_card_bank"
                         show-overflow-tooltip
                         label="社保发卡行"></el-table-column>
        <el-table-column prop="health_insurance_card_bank"
                         show-overflow-tooltip
                         label="医保发卡行"></el-table-column>
        <el-table-column show-overflow-tooltip
                         label="是否愿意改用">
          <template slot-scope="scope">
            <span v-if="scope.row.is_willing">
              <el-badge is-dot
                        type="success"
                        class="mr-15"></el-badge>愿意
            </span>
            <span v-else>
              <el-badge is-dot
                        type="danger"
                        class="mr-15"></el-badge>不愿意
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="web_source"
                         show-overflow-tooltip
                         label="来源"></el-table-column>
        <el-table-column prop="marketer_name"
                         show-overflow-tooltip
                         label="订单状态">
          <template slot-scope="scope">
            <span v-if="scope.row.status==='CONVERTED'">
              <el-badge is-dot
                        type="success"
                        class="mr-15"></el-badge>已提货
            </span>
            <span v-else
                  @click="pick({...scope.row,...paramsObj})">
              <el-badge is-dot
                        type="danger"
                        class="mr-15"></el-badge>未提货
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="created_at"
                         show-overflow-tooltip
                         label="提交时间"></el-table-column>
        <el-table-column prop="converted_at"
                         show-overflow-tooltip
                         label="提货时间"></el-table-column>
      </table-page>
      <span slot="footer"
            class="dialog-footer"></span>
    </el-dialog>
    <PickGood ref="pickgood"
              @success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import PickGood from './PickGood'
import { fetchResearchCustomers } from '@/api/market'
export default {
  props: ['params'],
  data () {
    return {
      fetchResearchCustomers,
      dialogVisible: false,
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'WAIT_CONVERTED',
        label: '未提货'
      }, {
        value: 'CONVERTED',
        label: '已提货'
      }],
      paramsObj: {},
      search: [{
        type: 'select',
        value: '',
        name: '搜索类型',
        key: 'search_type',
        list: [
          {
            value: 'NAME',
            label: '姓名'
          }, {
            value: 'TEL',
            label: '电话号码'
          }, {
            value: 'ID_NUMBER',
            label: '身份证号码'
          }
        ]
      }, {
        type: 'input',
        value: '',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }]
    }
  },
  components: {
    TablePage,
    PickGood
  },
  methods: {
    showTable (row, acId) {
      this.paramsObj = {
        activity_id: acId,
        research_area_id: row.id
      }
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    pick (row) {
      this.$refs.pickgood.showModal(row)
    }
  },
  mounted () { }
}
</script>

<style lang="less" scoped></style>
